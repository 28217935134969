import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import AdminDocumentTable from '../AdminDocumentTable';
import ClientDocumentTable from '../ClientDocumentTable/ClientDocumentTable';
import Popup from '../Popup';

import { postResults } from '../../thunks/postresults';
import * as submissionThunks from '../../thunks/submission';
import * as errorActions from '../../actions/error';
import { setDocuments, setErroredDocuments } from '../../reducers/submissions';

import { downloadDocumentWithBlob } from '../../helper/functions';
import { getGroupedDocuments, addLabelsToGroupedDocuments } from './document-library-logic';

import './DocumentLibrary.scss';

const DocumentLibrary = (props) => {
  const {
    token,
    submission,
    submissionId,
    groups,
    stageId,
    getDocuments,
    setDocuments,
    setErroredDocuments,
    postResults,
    adminView,
    descriptionShown,
    search,
    module,
    throwError,
  } = props;

  const [documentNotReady, setDocumentNotReady] = useState(null);
  const [popup, setPopup] = useState({
    shown: false,
    docToDelete: null,
  });
  const [isRunning, setIsRunning] = useState(true);
  const [resultPosted, setResultPosted] = useState(false);
  const [groupedDocuments, setGroupedDocuments] = useState({});

  useEffect(() => {
    getDocuments(submissionId, token);
  }, []);

  useEffect(() => {
    if (submission && submission.documents) {
      if (!submission.documents.length) {
        setIsRunning(false);
        if (stageId && module && !module.autoCompleteSubmission && !submission.completed && !resultPosted) {
          postResults(submissionId, stageId, token);
          setResultPosted(true);
        }
      } else {
        setDocumentNotReady(
          submission.documents.filter(
            (d) => (d.dataFlow !== 'upload' || d.dataFlow === 'download') && !d.generatedFilePath
          )
        );
      }
    }
  }, [submission, module]);

  useEffect(() => {
    if (documentNotReady) {
      setIsRunning(true);
    }
    if (documentNotReady === undefined) {
      setIsRunning(false);
      if (stageId && module && !module.autoCompleteSubmission && !submission.completed) {
        postResults(submissionId, stageId, token);
      }
    }
  }, [documentNotReady, module]);

  useEffect(() => {
    if (!submission || !submission.documents || !groups || Object.keys(groups).length === 0) {
      return;
    }

    if (!submission.loading) {
      setGroupedDocuments(
        addLabelsToGroupedDocuments(getGroupedDocuments(submission.documents), submission.data, groups)
      );
    }
  }, [submission, groups]);

  const getDocumentsTryCount = useRef(0);
  const getDocumentsTryDone = useRef(false);
  useEffect(() => {
    if (!submission || !submission.documents || getDocumentsTryDone.current) {
      return;
    }

    if (getDocumentsTryCount.current === 5) {
      getDocumentsTryDone.current = true;
      setErroredDocuments({ submissionId, documents: submission.documents, erroredDocuments: documentNotReady });
    } else {
      const timeout = setTimeout(() => {
        getDocuments(submissionId, token);
        getDocumentsTryCount.current = getDocumentsTryCount.current + 1;
      }, 12000);

      return () => clearTimeout(timeout);
    }
  }, [submission]);

  const uploadDocument = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const documentId = e.target.name;
    setDocuments({
      submissionId,
      documents: submission.documents.map((d) => {
        if (d._id !== documentId) return d;
        return {
          ...d,
          uploading: true,
        };
      }),
    });
    const formData = new FormData();
    formData.append('file', file);
    axios
      .post('/drf/submissions/' + submissionId + '/documents/' + documentId + '/upload', formData, {
        headers: {
          Authorization: 'Bearer ' + token,
          'content-type': 'multipart/form-data',
        },
      })
      .then((response) =>
        setDocuments({
          submissionId,
          documents: submission.documents.map((d) => {
            if (d._id !== documentId) return d;
            return {
              ...d,
              ...response.data,
              uploading: false,
            };
          }),
        })
      )
      .catch((e) => {
        console.log(e);
        setDocuments({
          submissionId,
          documents: submission.documents.map((d) => {
            if (d._id !== documentId) return d;
            return {
              ...d,
              uploading: false,
            };
          }),
        });
      });
  };

  const deleteDocument = (doc) => {
    if (!doc.filePath) return;
    const documentId = doc._id;
    axios
      .delete('/drf/submissions/' + submissionId + '/documents/' + documentId + '/upload', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) =>
        setDocuments({
          submissionId,
          documents: submission.documents.map((d) => {
            if (d._id !== documentId) return d;
            return {
              ...d,
              filePath: null,
              fileName: '',
            };
          }),
        })
      )
      .catch((e) => throwError(e));
  };

  const setDocumentSettings = (key, value, docId) => {
    axios
      .put(
        '/admin/drf/submissions/' + submissionId + '/documents/set-states',
        [
          {
            documentId: docId,
            [key]: value,
          },
        ],
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(() =>
        setDocuments({
          submissionId,
          documents: submission.documents.map((d) => {
            if (d._id !== docId) return d;
            return {
              ...d,
              [key]: value,
            };
          }),
        })
      )
      .catch((e) => throwError(e));
  };

  const downloadDocument = (doc, withFilePath = false) => {
    downloadDocumentWithBlob(doc, submissionId, token, withFilePath);
  };

  const openDeletePopup = (doc) =>
    setPopup({
      shown: true,
      docToDelete: doc,
    });

  const closeDeletePopup = () =>
    setPopup({
      shown: false,
      docToDelete: null,
    });

  return (
    <div className="document-library-content-container hide-scrollbar">
      <Popup
        popupShown={popup.shown}
        popupTitle={I18n.t('delete-document/popup-text')}
        okHandler={() => {
          deleteDocument(popup.docToDelete);
          closeDeletePopup();
        }}
        cancelHandler={closeDeletePopup}
      >
        <span>{I18n.t('delete-document/popup-text')}</span>
      </Popup>
      {!adminView && (
        <div className="progress-container">
          <div className="progress-box z-index-4">
            <Translate value="document-library/progress-box-1" />
          </div>
          <div className="progress-box z-index-3">
            <Translate value="document-library/progress-box-2" />
          </div>
          <div className="progress-box z-index-2">
            <Translate value="document-library/progress-box-3" />
          </div>
          <div className="progress-box z-index-1">
            <Translate value="document-library/progress-box-4" />
          </div>
        </div>
      )}
      {adminView ? (
        <AdminDocumentTable
          documents={groupedDocuments}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          descriptionShown={descriptionShown}
          search={search}
          setDocumentSettings={setDocumentSettings}
          openDeletePopup={openDeletePopup}
          closeDeletePopup={closeDeletePopup}
          ableToUploadAndDelete={true}
          submissionId={submissionId}
          submission={submission}
        />
      ) : (
        <ClientDocumentTable
          documents={groupedDocuments}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          openDeletePopup={openDeletePopup}
          closeDeletePopup={closeDeletePopup}
          ableToUploadAndDelete={true}
          submission={submission}
        />
      )}
    </div>
  );
};

DocumentLibrary.propTypes = {
  authenticated: PropTypes.bool,
  token: PropTypes.string,
  userData: PropTypes.object,
  submission: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
  const submissionIdOnClientSide = ownProps.match.params.submissionId;
  const submissionIdOnAdminSide = ownProps.submissionId;
  const submissionId = submissionIdOnClientSide ? submissionIdOnClientSide : submissionIdOnAdminSide;
  const stageId = ownProps.match.params.stageId;
  const submission = state.submissions[submissionId];

  const module = submission ? state.modules.modules[submission.moduleId] : null;
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    userData: state.auth.userData,
    groups: state.modules.groups,
    submission,
    submissionId,
    module,
    stageId,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSubmission: submissionThunks.getSubmission,
      postResults,
      getDocuments: submissionThunks.getDocuments,
      setDocuments,
      setErroredDocuments,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentLibrary));
