import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Group from '../Group';
import ServerTable from '../ServerTable';

import * as moduleActions from '../../actions/modules';
import { isProcessViewEnabled } from '../../helper/functions';

import './FormGroups.scss';

class FormGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (isProcessViewEnabled(this.props.userData.rules)) {
      if (!this.props.originalValues.isUpToDate || this.props.stageResults.length !== prevProps.stageResults.length) {
        const newValues = getGroupResultValuesAsString(this.props.groupMeta, this.props.stageResults);
        this.props.setOriginalValues(newValues);
      } else {
        const newValues = getGroupResultValuesAsString(this.props.groupMeta, this.props.stageResults);
        this.props.setIsDirty && this.props.setIsDirty(this.props.originalValues.value !== newValues);
      }
    }
  }

  componentWillUnmount() {
    this.props.setIsDirty && this.props.setIsDirty(false);
  }

  render() {
    const { groups, groupMeta, stageResults, params } = this.props;
    if (!stageResults) {
      return null;
    }
    return (
      <div className="groups form-group-container">
        {groupMeta.map((gm, index) => {
          const group = groups[gm.groupId];
          const groupResults = stageResults.filter((sr) => sr.groupId === gm.groupId);

          if (gm.renderType && gm.renderType === 'server-table') {
            return (
              <ServerTable
                params={params}
                key={gm.groupId}
                groupMeta={gm}
                groupResults={groupResults}
                group={group}
                submissionId={this.props.params.submissionId}
              />
            );
          } else {
            return (
              <Group
                groupMeta={gm}
                groupResults={groupResults}
                group={group}
                submissionId={this.props.params.submissionId}
                key={gm.groupId}
                params={params}
              />
            );
          }
        })}
      </div>
    );
  }
}

Group.propTypes = {};

function mapStateToProps(state, ownProps) {
  const { params } = ownProps;
  const submission = state.submissions[params.submissionId];
  const stageGroups = ownProps.groupMeta.map((gm) => gm.groupId);
  const stageResults =
    submission && submission.data ? submission.data.filter((s) => stageGroups.includes(s.groupId)) : [];
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    groups: state.modules.groups,
    stageResults,
    userData: state.auth.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setModuleData: moduleActions.setModuleData,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FormGroups);

function getGroupResultValuesAsString(groupMeta, stageResults) {
  const groupResults = [];
  groupMeta.map((gm) => {
    groupResults.push(stageResults.filter((sr) => sr.groupId === gm.groupId));
  });

  return JSON.stringify(
    groupResults.map((x) =>
      x.map((y) =>
        y.values.map((z) => {
          if (z.value instanceof Object && z.value.value) {
            return z.value.value;
          } else if (z.value instanceof Object && z.value.id) {
            return z.value.id;
          } else {
            return z.value;
          }
        })
      )
    )
  );
}
