import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';

import auth from './reducers/auth';
import entity from './reducers/entity';
import layout from './reducers/layout';
import modules from './reducers/modules';
import submissions from './reducers/submissions';
import error from './reducers/error';

const rootReducer = combineReducers({
  i18n: i18nReducer,
  error,
  auth,
  entity,
  layout,
  modules,
  submissions,
});

export default rootReducer;
