import { setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import moment from 'moment';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import rootRedcuer from './rootReducer';
import { getBrowserLang } from './helper/functions';

const persistedLocalStorageObject = localStorage.getItem('localStorageObject')
  ? JSON.parse(localStorage.getItem('localStorageObject'))
  : {};

const store = configureStore({
  reducer: rootRedcuer,
  middleware: getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
  preloadedState: {
    ...persistedLocalStorageObject,
  },
});

syncTranslationWithStore(store);

store.subscribe(() => {
  const lSobj = {
    auth: store.getState().auth,
    layout: store.getState().layout,
  };
  localStorage.setItem('localStorageObject', JSON.stringify(lSobj));
});

let currentLocale;
if (localStorage.getItem('currentLocale')) {
  currentLocale = localStorage.getItem('currentLocale');
}
moment.locale(currentLocale || getBrowserLang());
store.dispatch(setLocale(currentLocale || getBrowserLang()));

export default store;
