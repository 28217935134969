import axios from 'axios';

import { setSubmissionLoading, setStageResults, validateFields } from '../reducers/submissions';

export function postResults(submissionId, stageId, token, stageResults = [], fields = {}, callback = null) {
  const stageResultsWithOutNullValues = stageResults
    .map((stageResult) => {
      return {
        ...stageResult,
        values: stageResult.values.filter((value) => {
          return value.value || value.value === false;
        }),
      };
    })
    .filter((stageResult) => stageResult.values.length > 0);

  return (dispatch) => {
    dispatch(setSubmissionLoading({ submissionId, loading: true }));
    const status = {};
    axios
      .put(
        '/drf/submissions/' + submissionId,
        {
          stageId,
          results: stageResultsWithOutNullValues,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        status.state = 'success';
        dispatch(setStageResults({ submissionId, groupResults: response.data, stageId, status }));
        dispatch(setSubmissionLoading({ submissionId, loading: false }));
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          status.state = 'fieldError';
          status.errors = e.response.data.error;
        } else {
          status.state = 'generalError';
        }

        const mappedErrors = status.errors.map((originalError) => ({
          fieldId: originalError.property,
          message: originalError.error,
        }));

        dispatch(setStageResults({ submissionId, groupResults: undefined, stageId, status }));
        dispatch(validateFields({ submissionId, errors: mappedErrors, fields }));
      })
      .finally(() => {
        if (callback) callback(status);
      });
  };
}
