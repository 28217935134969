import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router-dom';

import AdminDataSummary from '../AdminDataSummary';
import Authorization from '../Authorization';
import BackButton from '../BackButton';
import DocumentLibrary from '../DocumentLibrary';
import Filterbar from '../Filterbar';
import Popup from '../Popup';
import SubHeader from '../SubHeader';
import TextWithTooltip from '../TextWithTooltip';
import ViewOptions from '../ViewOptions';
import WrappedLink from '../WrappedLink';
import SelectButton from '../SelectButton';

import * as errorActions from '../../actions/error';
import * as submissionThunks from '../../thunks/submission';
import { setSubmissionOverride } from '../../reducers/submissions';
import { translate, useIsBigScreen, isProcessViewEnabled } from '../../helper/functions';
import { calculateRoutePath } from '../../helper/navigation';

import './AdminSubmissionView.scss';

import stop from '../../assets/stop_process.svg';
import continueIcon from '../../assets/continue_process.svg';

const AdminSubmissionView = (props) => {
  const {
    token,
    history,
    match,
    submission,
    stages,
    setSubmissionOverride,
    getSubmission,
    getDocuments,
    userData,
    throwError,
    stageMeta,
  } = props;
  const usersMainRoute = userData && userData.mainRoute;
  const [popup, setPopup] = useState({
    shown: false,
    inputText: '',
  });
  const [search, setSearch] = useState('');
  const [finishPopup, setFinishPopup] = useState({
    shown: false,
    message: I18n.t('submission/finish-confirmation'),
    state: 'confirmation',
  });
  const [isFinishable, setIsFinishable] = useState(false);

  useEffect(() => {
    getSubmission(submissionId, token);
    getDocuments(submissionId, token);
  }, [match.params.submission]);

  useEffect(() => {
    if (submission && submission.data && submission.documents) {
      const allGroupsApproved =
        !!submission.data.length &&
        submission.data.every((data) => data.values.every((field) => field.state === 'approved'));
      const allDocumentsApproved = submission.documents.every((doc) => doc.state === 'approved');
      setIsFinishable(allGroupsApproved && allDocumentsApproved);
    }
  }, [submission]);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname.includes('data')) {
        getSubmission(submissionId, token);
      }
    });
  }, [history]);

  const stopOrContinue = () => {
    let blockContent = processBlocked ? null : popup.inputText;
    axios
      .put(
        'admin/drf/submissions/' + submissionId + '/block',
        {
          isBlocked: blockContent,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(() => {
        setSubmissionOverride({ submissionId, isBlocked: blockContent });
        closePopup();
      })
      .catch((e) => {
        throwError(e);
      });
  };

  const closePopup = () => {
    setPopup({
      inputText: '',
      shown: false,
    });
  };

  const confirmFinishing = () => {
    axios
      .post(
        'admin/drf/submissions/' + submissionId + '/complete',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(() => {
        setFinishPopup({
          ...finishPopup,
          message: I18n.t('submission/finish-success'),
          state: 'completed',
        });
      })
      .catch((e) => {
        console.log(e);
        setFinishPopup({
          ...finishPopup,
          message: I18n.t('submission/finish-fail'),
          state: 'failed',
        });
      });
  };
  const closeProcess = () => {
    setFinishPopup({ ...finishPopup, shown: false });
    history.push(calculateRoutePath(usersMainRoute, '/client-management'));
  };
  const finishPopupOkHandler = () => {
    switch (finishPopup.state) {
      case 'confirmation':
        confirmFinishing();
        break;
      case 'completed':
        closeProcess();
        break;
      default:
        break;
    }
  };
  const finishPopupCancelHandler = () => {
    switch (finishPopup.state) {
      case 'confirmation':
        setFinishPopup({ ...finishPopup, shown: false });
      case 'failed':
        setFinishPopup({
          ...finishPopup,
          shown: false,
          message: I18n.t('submission/finish-confirmation'),
          state: 'confirmation',
        });
      default:
        break;
    }
  };

  const submissionId = match.params.submissionId;
  const processBlocked = submission && stages && (stages[submission.isBlocked] || submission.isBlocked);
  const blockTitle =
    processBlocked && stages[submission.isBlocked] ? (
      translate(stages[submission.isBlocked].description)
    ) : (
      <Translate value="stop-process/title" />
    );
  const blockContent =
    processBlocked && stages[submission.isBlocked] && stages[submission.isBlocked].blockContent
      ? translate(stages[submission.isBlocked].blockContent)
      : submission.isBlocked;
  const popupTitle = I18n.t(processBlocked ? 'continue-process/popup-title' : 'stop-process/popup-title');
  const popupText = I18n.t(processBlocked ? 'continue-process/popup-text' : 'stop-process/popup-text');
  const popupOkDisabled = !processBlocked && popup.inputText.length < 1;

  let routeInfo;
  if (history.location.pathname.includes('data')) {
    routeInfo = {
      title: I18n.t('pages/data-summary'),
      urlTo: `/client-management/admin/${submissionId}/documents`,
      buttonText: I18n.t('admin-submission/switch-to-library'),
    };
  }
  if (history.location.pathname.includes('documents')) {
    routeInfo = {
      title: I18n.t('pages/documents'),
      urlTo: `/client-management/admin/${submissionId}/data`,
      buttonText: I18n.t('admin-submission/switch-to-summary'),
    };
  }

  const propsToRoutes = {
    submissionId,
    search,
    adminView: true,
  };
  const filterbarProps = {
    searchbar: {
      shown: true,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => setSearch(event.target.value),
    },
    clearSearchAndFilter: () => setSearch(''),
  };

  const blockContainer = () => (
    <div className="block-attention-container">
      <span className="title">{blockTitle}</span>
      <span dangerouslySetInnerHTML={{ __html: blockContent }} />
    </div>
  );

  return (
    <div className="admin-submission-view">
      <div className="admin-submission-view-header-container">
        <ResponsiveSubHeader
          submission={submission}
          routeInfo={routeInfo}
          processBlocked={processBlocked}
          popup={popup}
          isFinishable={isFinishable}
          finishPopup={finishPopup}
          setFinishPopup={setFinishPopup}
          filterbarProps={filterbarProps}
          userData={userData}
          setPopup={setPopup}
          stageMeta={stageMeta}
          submissionId={submissionId}
        />
        {processBlocked && Object.keys(stages).length > 0 && blockContainer()}
      </div>
      <Switch>
        <Route
          path={`${match.url}/data`}
          render={(routeProps) => <AdminDataSummary {...routeProps} {...propsToRoutes} />}
        />
        <Route
          path={`${match.url}/documents`}
          render={(routeProps) => <DocumentLibrary {...routeProps} {...propsToRoutes} />}
        />
      </Switch>
      <Popup
        popupTitle={popupTitle}
        popupShown={popup.shown}
        okHandler={stopOrContinue}
        okDisabled={popupOkDisabled}
        cancelHandler={closePopup}
      >
        <span>{popupText}</span>
        {!processBlocked && (
          <div className="popup-textarea-container">
            <textarea onChange={(e) => setPopup({ ...popup, inputText: e.target.value })} />
          </div>
        )}
      </Popup>
      <Popup
        popupTitle={I18n.t('submission/finish')}
        popupShown={finishPopup.shown}
        cancelHandler={finishPopup.state !== 'completed' ? finishPopupCancelHandler : null}
        okHandler={finishPopup.state !== 'failed' ? finishPopupOkHandler : null}
      >
        <span className="finish-popup-messages">{finishPopup.message}</span>
      </Popup>
    </div>
  );
};

AdminSubmissionView.propTypes = {
  searchHandler: PropTypes.func,
  searchValue: PropTypes.string,
  viewMode: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const submissionId = ownProps.match.params.submissionId;
  const submission = state.submissions[submissionId] || {};
  const stageMeta =
    submission && state.modules.modules[submission.moduleId]
      ? state.modules.modules[submission.moduleId].stageMeta
      : [];
  return {
    token: state.auth.token,
    stages: state.modules.stages,
    submission,
    userData: state.auth.userData,
    stageMeta,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSubmissionOverride,
      getSubmission: submissionThunks.getSubmission,
      getDocuments: submissionThunks.getDocuments,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default Authorization(withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSubmissionView)), [
  'complete-submission',
]);

function ResponsiveSubHeader({
  submission,
  routeInfo,
  processBlocked,
  popup,
  isFinishable,
  finishPopup,
  setFinishPopup,
  filterbarProps,
  userData,
  setPopup,
  stageMeta,
  submissionId,
}) {
  const onFinishButtonClick = useCallback(() => setFinishPopup({ ...finishPopup, shown: true }), [finishPopup]);
  const isBigScreen = useIsBigScreen();

  if (isBigScreen) {
    return (
      <>
        <SubHeader>
          <div className="column">
            <BackButton
              className="negative-margin"
              title={I18n.t('submission/back-button')}
              backUrl={`/client-management/entity/${submission.entityId}/submissions`}
            />
            <div className="title add-margin-left">
              <TextWithTooltip textWithOverflow={routeInfo.title} />
            </div>
          </div>
          <div className="column">
            <ViewOptions options={['toggleFilterBar']} />
            <button
              className={
                isAnythingRejected(submission, processBlocked) ? 'inactive stop-and-continue' : 'stop-and-continue'
              }
              onClick={() => !isAnythingRejected(submission, processBlocked) && setPopup({ ...popup, shown: true })}
            >
              <img src={processBlocked ? continueIcon : stop} />
              <Translate value={processBlocked ? 'continue-process/stop-button' : 'stop-process/stop-button'} />
            </button>
            {isProcessViewEnabled(userData.rules) ? (
              <UserViewButtonGroup
                pathname={window.location.pathname}
                submissionId={submissionId}
                stageMeta={stageMeta}
              />
            ) : (
              <WrappedLink navLink={true} to={routeInfo.urlTo}>
                <button className="subheader-button">{routeInfo.buttonText}</button>
              </WrappedLink>
            )}
            <button
              className="subheader-button"
              onClick={onFinishButtonClick}
              disabled={submission.completed || !isFinishable}
            >
              {submission.data &&
                (!submission.completed ? I18n.t('submission/finish') : I18n.t('submission/completed'))}
            </button>
          </div>
        </SubHeader>
        <Filterbar {...filterbarProps} />
      </>
    );
  } else {
    return (
      <div>
        <div bp="full-width vertical-center flex" className="navigation-row-mobile">
          <div bp="fill">
            <BackButton
              className=""
              title={I18n.t('submission/back-button')}
              backUrl={`/client-management/entity/${submission.entityId}/submissions`}
            />
          </div>
          <div bp="fit" className="title add-margin-left">
            <TextWithTooltip textWithOverflow={routeInfo.title} />
          </div>
        </div>
        <div bp="flex" className="button-row-mobile">
          <div className="pull-left">
            <button
              className={
                isAnythingRejected(submission, processBlocked) ? 'inactive stop-and-continue' : 'stop-and-continue'
              }
              onClick={() => !isAnythingRejected(submission, processBlocked) && setPopup({ ...popup, shown: true })}
            >
              <img src={processBlocked ? continueIcon : stop} />
              <Translate value={processBlocked ? 'continue-process/stop-button' : 'stop-process/stop-button'} />
            </button>
            <WrappedLink navLink={true} to={routeInfo.urlTo}>
              <button className="subheader-button">{routeInfo.buttonText}</button>
            </WrappedLink>
            <button
              className="subheader-button"
              onClick={onFinishButtonClick}
              disabled={submission.completed || !isFinishable}
            >
              {submission.data &&
                (!submission.completed ? I18n.t('submission/finish') : I18n.t('submission/completed'))}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function isAnythingRejected(submission, processBlocked) {
  if (!submission || !processBlocked || !clientConfig.blockContinueProcessWhenRejected) {
    return false;
  }

  let anyRejectedSubmission = false;
  let anyRejectedDocument = false;

  if (submission.data) {
    anyRejectedSubmission = submission.data.some((s) => s.state === 'rejected');
  }

  if (submission.documents) {
    anyRejectedDocument = submission.documents.some((d) => d.state === 'rejected');
  }

  return anyRejectedSubmission || anyRejectedDocument;
}

function UserViewButtonGroup({ pathname, submissionId, stageMeta }) {
  const userViewUrl = useMemo(() => getUserViewUrl(submissionId, stageMeta), [submissionId, stageMeta]);
  let buttonsMeta;
  if (pathname && pathname.includes('data')) {
    buttonsMeta = [
      {
        navlink: false,
        to: '#',
        text: I18n.t('general/admin-submission-view'),
        className: 'subheader-button',
      },
      {
        navlink: true,
        to: userViewUrl,
        text: I18n.t('submission/switch-to-process-view'),
        className: 'subheader-button',
      },
      {
        navlink: true,
        to: `/client-management/admin/${submissionId}/documents`,
        text: I18n.t('admin-submission/switch-to-library'),
        className: 'subheader-button',
      },
    ];
  } else {
    buttonsMeta = [
      {
        navlink: false,
        to: '#',
        text: I18n.t('general/library-view'),
        className: 'subheader-button',
      },
      {
        navlink: true,
        to: `/client-management/admin/${submissionId}/data`,
        text: I18n.t('admin-submission/switch-to-summary'),
        className: 'subheader-button',
      },
      {
        navlink: true,
        to: userViewUrl,
        text: I18n.t('submission/switch-to-process-view'),
        className: 'subheader-button',
      },
    ];
  }

  return <SelectButton buttonsMeta={buttonsMeta} />;
}

function getUserViewUrl(submissionId, stageMeta) {
  if (!stageMeta) {
    return '/';
  }

  const foundStageMeta = stageMeta.find((s) => s.position === 0);

  if (!foundStageMeta) {
    return '/';
  }

  return `/client-management/${submissionId}/${foundStageMeta.stageId}`;
}
