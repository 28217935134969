import axios from 'axios';

import * as moduleActions from '../actions/modules';
import * as errorActions from '../actions/error';

import { setSubmissionLoading, setSubmissionData, setDocuments } from '../reducers/submissions';

export function getSubmission(submissionId, token) {
  return (dispatch) => {
    dispatch(setSubmissionLoading({ submissionId, loading: true }));
    const header = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    let submissionData;
    axios
      .get('/drf/submissions/' + submissionId, header)
      .then((response) => {
        submissionData = response.data;
        return axios.get('/drf/modules/' + response.data.moduleId, header);
      })
      .then((response) => {
        const fields = {};
        response.data.fields.forEach((f) => (fields[f._id] = { ...f }));
        dispatch(setSubmissionData({ data: submissionData, fields }));
        dispatch(moduleActions.setModuleData(response.data));
      })
      .catch((error) => {
        dispatch(errorActions.throwServerError(error));
      });
  };
}

export function getDocuments(submissionId, token) {
  return (dispatch) => {
    const header = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    let documents = [];
    axios
      .get(`/drf/submissions/${submissionId}/documents`, header)
      .then((response) => {
        if (response.data.length) {
          documents = response.data;
        }
        dispatch(setDocuments({ submissionId, documents: response.data }));
      })
      .catch((error) => {
        dispatch(errorActions.throwServerError(error));
      });
  };
}
