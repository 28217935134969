import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import EntityTable from '../EntityTable';
import ResponsiveTileView from '../ResponsiveTileView';
import Authorization from '../Authorization';
import WelcomePage from '../WelcomePage';
import MobileHeaderLinks from '../MobileHeaderLinks';
import Filter from '../Filter';

import * as entityThunks from '../../thunks/entity';
import { translate, filterBy, useGetViewMode, useIsBigScreen } from '../../helper/functions';
import { calculateRoutePath } from '../../helper/navigation';

import './EntityList.scss';

const EntityList = (props) => {
  const componentName = 'entityList';
  const { isLoaded, entities, fetchEntities, authenticated, history, userData, viewMode, modules } = props;
  const vm = useGetViewMode(viewMode, componentName);
  const [entityList, setEntityList] = useState([]);
  const tableHeaderColumns =
    clientConfig &&
    (userData && (userData.role === 'admin' || userData.role === 'super-admin' || userData.role === 'lawyer')
      ? clientConfig.tableHeaders.entityDetails.admin
      : clientConfig.tableHeaders.entityDetails.client);
  const usersMainRoute = userData && userData.mainRoute;
  const isBigScreen = useIsBigScreen();

  const goToEntity = (e) => history.push(calculateRoutePath(usersMainRoute, '/client-management/entity/' + e._id));
  const [viewProps, setViewProps] = useState({
    entities: [],
    tableHeaderColumns,
    goToEntity,
  });
  const defaultSearchAndFilter = {
    search: '',
    activeProcess: false,
    state: '',
    module: '',
  };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);

  useEffect(() => {
    if (authenticated) {
      fetchEntities();
    }
  }, []);
  useEffect(() => {
    if (clientConfig && clientConfig.maxEntityCount === 1 && entities.length === 1) {
      history.push(calculateRoutePath(usersMainRoute, '/client-management/entity/' + entities[0]._id));
    }
  }, [entities]);
  useEffect(() => {
    setEntityList(
      entities.filter((e) =>
        filterBy(
          e,
          [
            { type: 'text', itemProperties: ['data.name'], searchProperty: 'search' },
            { type: 'boolean', itemProperties: ['activeSubmission'], searchProperty: 'activeProcess' },
            { type: 'selector', itemProperties: ['state'], searchProperty: 'state' },
            { type: 'selector', itemProperties: ['creatorModule._id'], searchProperty: 'module' },
          ],
          searchAndFilter
        )
      )
    );
  }, [entities, searchAndFilter]);
  useEffect(() => {
    setViewProps({
      ...viewProps,
      entities: entityList,
    });
  }, [entityList]);

  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: viewProps.entities.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        });
      },
    },
    switchers: [
      {
        text: 'entity-details/only-with-open-submissions',
        checked: searchAndFilter.activeProcess,
        onSwitcherChange: () => {
          setSearchAndFilter({
            ...searchAndFilter,
            activeProcess: event.target.checked,
          });
        },
      },
    ],
    selects: [
      ...(Object.values(modules).filter((m) => m.autoCreateEntity).length > 1
        ? [
            {
              name: 'module',
              className: 'module-selector',
              selected: searchAndFilter.module,
              onSelectChange: (selectValue) =>
                setSearchAndFilter({
                  ...searchAndFilter,
                  module: selectValue,
                }),
              options: Object.values(modules)
                .filter((m) => m.autoCreateEntity)
                .map((m) => ({ value: m._id, label: translate(m.label) })),
            },
          ]
        : []),
      {
        name: 'state',
        className: 'state-selector',
        selected: searchAndFilter.state,
        onSelectChange: (selectValue) =>
          setSearchAndFilter({
            ...searchAndFilter,
            state: selectValue,
          }),
        options: [
          {
            value: 'completed',
            label: I18n.t('general/completed'),
          },
          {
            value: 'pending',
            label: I18n.t('general/pending'),
          },
          {
            value: 'incomplete',
            label: I18n.t('general/incomplete'),
          },
        ],
      },
    ],
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };

  if (!isLoaded) return null;
  if (entities.length === 0) return <WelcomePage />;

  return (
    <div className="entity-container container hide-scrollbar">
      <Filter
        componentName={componentName}
        filterbarProps={filterbarProps}
        searchAndFilter={searchAndFilter}
        setSearchAndFilter={setSearchAndFilter}
      />

      {vm === 'tile' ? (
        <>
          <ResponsiveTileView
            {...viewProps}
            tileContent={viewProps.entities}
            type="entity"
            onTileClick={goToEntity}
            defaultSort="name"
          />
          {!isBigScreen && <MobileHeaderLinks />}
        </>
      ) : (
        <EntityTable {...viewProps} />
      )}
    </div>
  );
};

EntityList.propTypes = {
  entities: PropTypes.array,
  isLoaded: PropTypes.bool,
  fetchEntities: PropTypes.func,
  authenticated: PropTypes.bool,
  userData: PropTypes.object,
  viewMode: PropTypes.object,
};

function mapStateToProps(state) {
  const { auth, entity, layout, modules } = state;
  return {
    authenticated: auth.authenticated,
    userData: auth.userData,
    entities: entity.entities.map((entity) =>
      entity.data && entity.data.name ? entity : { ...entity, data: { ...entity.data, name: I18n.t('general/draft') } }
    ),
    isLoaded: entity.isLoaded,
    viewMode: layout.viewMode,
    modules: modules.modules,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchEntities: entityThunks.fetchEntities,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(EntityList), ['list-entities']);
