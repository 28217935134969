import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import axios from 'axios';

import { getDateInLocalFormat, translate } from '../../helper/functions';
import { calculateRoutePath } from '../../helper/navigation';

export function createSubmissionCallback(popup, modules, entityId) {
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.userData);
  const history = useHistory();

  const headers = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  return useCallback(() => {
    const requestObject = {
      moduleId: popup.moduleId,
      name: popup.submissionName,
    };
    if (entityId) {
      requestObject.entityId = entityId;
    }
    if (popup.submissionName.length === 0) {
      requestObject.name = translate(modules[popup.moduleId].label) + ' ' + getDateInLocalFormat(new Date());
    }
    let promises = [
      axios.get('/drf/modules/' + popup.moduleId, headers),
      axios.post('/drf/submissions', requestObject, headers),
    ];
    const usersMainRoute = userData && userData.mainRoute;

    return Promise.all(promises)
      .then((response) => {
        history.push(
          calculateRoutePath(
            usersMainRoute,
            '/client-management/' + response[1].data._id + '/' + response[0].data.stageMeta[0].stageId
          )
        );
      })
      .catch((e) => {
        throwError(e);
      });
  }, [popup, modules, entityId]);
}
