import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';

import Svg from '../Svg';
import Popup from '../Popup';
import SelectButton from '../SelectButton';

import { postResults } from '../../thunks/postresults';
import { calculateRoutePath } from '../../helper/navigation';
import { isProcessViewEnabled } from '../../helper/functions';

import prev from '../../assets/blue_prev.svg';
import next from '../../assets/blue_next.svg';
import save from '../../assets/save.svg';

class NavigationButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNavigateToNextPopupOpen: false,
      isNavigateToPrevPopupOpen: false,
      isSavePopupOpen: false,
    };
  }

  openNavigateToNextPopup = () => {
    this.setState({ isNavigateToNextPopupOpen: true });
  };

  closeNavigateToNextPopup = () => {
    this.setState({ isNavigateToNextPopupOpen: false });
  };

  openNavigateToPrevPopup = () => {
    this.setState({ isNavigateToPrevPopupOpen: true });
  };

  closeNavigateToPrevPopup = () => {
    this.setState({ isNavigateToPrevPopupOpen: false });
  };

  openSavePopup = () => {
    this.setState({ isSavePopupOpen: true });
  };

  closeSavePopup = () => {
    this.setState({ isSavePopupOpen: false });
  };

  handleSubmit = (e, nextStageId, complete = false) => {
    e.preventDefault();
    const { stageId, submission, params, token, stageResults, userData, history, fields } = this.props;
    const submissionId = params.submissionId;
    const usersMainRoute = userData && userData.mainRoute;

    this.props.postResults(submissionId, stageId, token, stageResults, fields, (status) => {
      if (status.state === 'success') {
        const routeToPush = complete
          ? submission.entityId
            ? `/client-management/entity/${submission.entityId}`
            : '/client-management'
          : `/client-management/${params.submissionId}/${nextStageId}`;
        history.push(calculateRoutePath(usersMainRoute, routeToPush));
      }
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    const { stageId, params, token, stageResults, fields } = this.props;
    const submissionId = params.submissionId;

    this.props.postResults(submissionId, stageId, token, stageResults, fields, (status) => {
      this.props.setOriginalValuesToOutOfDate();
      console.log('Success!: ', status);
    });
  };

  handlePreviousClick = (prevStageId) => {
    if (!prevStageId) {
      return;
    }

    if (this.props.isDirty) {
      this.openNavigateToPrevPopup();
    } else {
      let prevLink;
      if (isProcessViewEnabled(this.props.userData.rules)) {
        this.props.setOriginalValuesToOutOfDate();
        prevLink = `/client-management/${this.props.params.submissionId}/${prevStageId}`;
      } else {
        prevLink = `/${this.props.params.submissionId}/${prevStageId}`;
      }

      this.props.history.push(prevLink);
    }
  };

  render() {
    const { filteredStageMeta, stage, submission, stageResults, params, module } = this.props;
    const { isBlocked, completed } = submission;

    if (!submission || !filteredStageMeta.length) {
      return null;
    }

    const currentPosition = filteredStageMeta.findIndex((sm) => sm.stageId === stage._id);
    const blockedPosition = filteredStageMeta.findIndex((sm) => sm.stageId === isBlocked);
    const prevStageId = currentPosition > 0 ? filteredStageMeta[currentPosition - 1].stageId : null;
    const nextStageId =
      currentPosition + 1 < filteredStageMeta.length ? filteredStageMeta[currentPosition + 1].stageId : null;
    const notBlocked = !isBlocked || currentPosition < blockedPosition;

    const formValid =
      stageResults.length > 0 &&
      !stageResults.find((stageResult) => {
        return stageResult.values.find((value) => {
          return value.error;
        });
      });

    return (
      <div className="prev-next-container form-style">
        <NavigateAwayPopup
          isOpen={this.state.isNavigateToNextPopupOpen}
          handleClose={this.closeNavigateToNextPopup}
          history={this.props.history}
          submissionId={params.submissionId}
          nextStageId={nextStageId}
          setOriginalValuesToOutOfDate={this.props.setOriginalValuesToOutOfDate}
        />
        <NavigateAwayPopup
          isOpen={this.state.isNavigateToPrevPopupOpen}
          handleClose={this.closeNavigateToPrevPopup}
          history={this.props.history}
          submissionId={params.submissionId}
          nextStageId={prevStageId}
          setOriginalValuesToOutOfDate={this.props.setOriginalValuesToOutOfDate}
        />
        <SavePopup
          isOpen={this.state.isSavePopupOpen}
          handleOpen={this.openSavePopup}
          handleClose={this.closeSavePopup}
          handleOk={this.handleSave}
        />
        <ButtonContainer
          prevStageId={prevStageId}
          nextStageId={nextStageId}
          completed={completed}
          module={module}
          userData={this.props.userData}
          formValid={formValid}
          stage={stage}
          notBlocked={notBlocked}
          submission={submission}
          submissionId={params.submissionId}
          isDirty={this.props.isDirty}
          history={this.props.history}
          handlePreviousClick={this.handlePreviousClick}
          handleSubmit={this.handleSubmit}
          handleSave={this.handleSave}
          openNavigateToNextPopup={this.openNavigateToNextPopup}
          openSavePopup={this.openSavePopup}
          setOriginalValuesToOutOfDate={this.props.setOriginalValuesToOutOfDate}
        />
      </div>
    );
  }
}

NavigationButtons.propTypes = {
  authenticated: PropTypes.bool,
  token: PropTypes.string,
  isDirty: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  const stage = state.modules.stages[ownProps.params.stageId];
  const stageGroups = stage.groupMeta.map((gm) => gm.groupId);
  const stageResults =
    ownProps.submission && ownProps.submission.data
      ? ownProps.submission.data.filter((s) => stageGroups.includes(s.groupId))
      : [];
  const stageId = ownProps.params.stageId;
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    entities: state.entity.entities,
    stages: state.modules.stages,
    userData: state.auth.userData,
    module: ownProps.submission ? state.modules.modules[ownProps.submission.moduleId] : null,
    fields: state.modules.fields,
    stage,
    stageId,
    stageResults,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postResults,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationButtons);

function NavigateAwayPopup({ isOpen, handleClose, history, submissionId, nextStageId, setOriginalValuesToOutOfDate }) {
  const handleOk = () => {
    handleClose();
    setOriginalValuesToOutOfDate();
    navigateToNext(history, submissionId, nextStageId);
  };

  return (
    <Popup
      popupShown={isOpen}
      popupTitle={I18n.t('general/navigate-away-promt')}
      cancelHandler={() => handleClose()}
      okHandler={handleOk}
    >
      <div className="form-container">
        <span>{I18n.t('general/navigate-away-promt-text')}</span>
      </div>
    </Popup>
  );
}

function navigateToNext(history, submissionId, nextStageId) {
  history.push(`/client-management/${submissionId}/${nextStageId}`);
}

function SavePopup({ isOpen, handleClose, handleOk }) {
  const handleOkClick = (e) => {
    handleClose();
    handleOk(e);
  };

  return (
    <Popup
      popupShown={isOpen}
      popupTitle={I18n.t('general/save-promt')}
      cancelHandler={() => handleClose()}
      okHandler={handleOkClick}
    >
      <div className="form-container">
        <span>{I18n.t('general/save-promt-text')}</span>
      </div>
    </Popup>
  );
}

function ButtonContainer({
  prevStageId,
  nextStageId,
  completed,
  module,
  userData,
  formValid,
  stage,
  notBlocked,
  submission,
  submissionId,
  isDirty,
  history,
  handlePreviousClick,
  handleSubmit,
  handleSave,
  openNavigateToNextPopup,
  openSavePopup,
  setOriginalValuesToOutOfDate,
}) {
  const isProcessView = isProcessViewEnabled(userData.rules);

  return (
    <div className="button-container">
      {isProcessView && <UserViewButtonGroup submissionId={submissionId} />}
      <PrevButton
        isProcessView={isProcessView}
        prevStageId={prevStageId}
        completed={completed}
        handlePreviousClick={handlePreviousClick}
      />
      {nextStageId || module.autoCompleteSubmission ? (
        <NextButtons
          isProcessView={isProcessView}
          handleSubmit={handleSubmit}
          handleSave={handleSave}
          nextStageId={nextStageId}
          autoCompleteSubmission={module.autoCompleteSubmission}
          formValid={formValid}
          stageType={stage.type}
          notBlocked={notBlocked}
          completed={completed}
          submissionLoading={submission.loading}
          submissionId={submissionId}
          isDirty={isDirty}
          history={history}
          openNavigateToNextPopup={openNavigateToNextPopup}
          openSavePopup={openSavePopup}
          setOriginalValuesToOutOfDate={setOriginalValuesToOutOfDate}
        />
      ) : null}
    </div>
  );
}

function NextButtons({
  isProcessView,
  handleSubmit,
  handleSave,
  nextStageId,
  autoCompleteSubmission,
  formValid,
  stageType,
  notBlocked,
  completed,
  submissionLoading,
  submissionId,
  isDirty,
  history,
  openNavigateToNextPopup,
  openSavePopup,
  setOriginalValuesToOutOfDate,
}) {
  const handleNextClick = () => {
    if (isDirty) {
      openNavigateToNextPopup();
    } else {
      setOriginalValuesToOutOfDate();
      navigateToNext(history, submissionId, nextStageId);
    }
  };

  const handleSaveClick = (e) => {
    if (isDirty) {
      openSavePopup();
    } else {
      handleSave(e);
    }
  };

  if (isProcessView) {
    return (
      <>
        {nextStageId && (
          <button className="next small" onClick={handleNextClick}>
            <Svg src={next} otherColor="#FFF" />
          </button>
        )}
        <button onClick={handleSaveClick} className="next small" disabled={!isDirty || completed}>
          <Svg src={save} otherColor="#FFF" />
        </button>
      </>
    );
  } else {
    if ((formValid || stageType !== 'data-input') && notBlocked && !completed && !submissionLoading) {
      return (
        <button onClick={(e) => handleSubmit(e, nextStageId, !nextStageId && autoCompleteSubmission)} className="next">
          <span>
            {I18n.t(!nextStageId && autoCompleteSubmission ? 'general/complete-submission' : 'general/save-and-next')}
          </span>
          <Svg src={next} otherColor="#FFF" />
        </button>
      );
    } else {
      return (
        <button className="next inactive">
          <span>{I18n.t('general/save-and-next')}</span>
          <Svg src={next} otherColor="#FFF" />
        </button>
      );
    }
  }
}

function PrevButton({ isProcessView, prevStageId, completed, handlePreviousClick }) {
  if (isProcessView) {
    return (
      <button className={`prev small ${!prevStageId && 'inactive'}`} onClick={() => handlePreviousClick(prevStageId)}>
        <Svg src={prev} otherColor="#FFF" />
      </button>
    );
  } else {
    return (
      <>
        {prevStageId && !completed ? (
          <button className="prev" onClick={() => handlePreviousClick(prevStageId)}>
            <Svg src={prev} otherColor="#FFF" />
            <span>{I18n.t('general/previous')}</span>
          </button>
        ) : (
          <button className="prev inactive">
            <Svg src={prev} otherColor="#FFF" />
            <span>{I18n.t('general/previous')}</span>
          </button>
        )}
      </>
    );
  }
}

function UserViewButtonGroup({ submissionId }) {
  const buttonsMeta = [
    {
      navlink: false,
      to: '#',
      text: I18n.t('general/process-view'),
      className: 'subheader-button',
    },
    {
      navlink: true,
      to: `/client-management/admin/${submissionId}/data`,
      text: I18n.t('submission/switch-to-admin-view'),
      className: 'subheader-button',
    },
    {
      navlink: true,
      to: `/client-management/admin/${submissionId}/documents`,
      text: I18n.t('admin-submission/switch-to-library'),
      className: 'subheader-button',
    },
  ];

  return <SelectButton buttonsMeta={buttonsMeta} />;
}
