import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import queryString from 'query-string';
import axios from 'axios';

import InputWithError from '../InputWithError';
import Footer from '../Footer';

import * as authActions from '../../actions/auth';
import { formValidator } from '../../helper/functions';

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        password: '',
        confirmPassword: '',
      },
      token: '',
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/');
    }
    const query = queryString.parse(this.props.location.search);
    this.setState({
      token: query.code,
    });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((state) => {
      return {
        formData: {
          ...state.formData,
          [target.name]: target.value,
        },
        errors: {
          ...state.errors,
          ...formValidator(
            target.name,
            target.value,
            target.name === 'confirmPassword' && this.state.formData.password
          ),
        },
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('/auth/reset-password', { ...this.state.formData, type: 'password', token: this.state.token })
      .then((response) => {
        this.props.history.push('/');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { formData, errors } = this.state;
    const formValid =
      Object.keys(errors)
        .map((k) => errors[k])
        .filter((e) => e).length === 0;
    return (
      <div className="authentication-page-content">
        <div className="form-wrapper no-header">
          <h1>
            <Translate value="new-password/title" />
          </h1>
          <h4>
            <Translate value="new-password/new-password" />
          </h4>
          <div className="form-container">
            <form onSubmit={this.handleSubmit}>
              <InputWithError
                placeholder="••••••••••"
                name="password"
                type="password"
                value={formData.password}
                onChange={this.handleInputChange}
                errorMsg={errors.password}
              />
              <InputWithError
                placeholder="••••••••••"
                name="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={this.handleInputChange}
                errorMsg={errors.confirmPassword}
              />
              <h4>
                <Translate value="new-password/description" />
              </h4>
              <div className="button-container">
                <button className="medium" type="submit" disabled={!formValid}>
                  <Translate value="form/save" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ResetPassword.propTypes = {
  token: PropTypes.string,
  login: PropTypes.func,
  authenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login: authActions.login,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
