import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';

import EditableName from '../EditableName';
import StatusIndicator from '../StatusIndicator';
import Svg from '../Svg';
import Authorization from '../Authorization';
import TextWithTooltip from '../TextWithTooltip';
import ResponsiveSubHeader from '../SubHeader/ResponsiveSubHeader';

import ResponsiveTable from '../ResponsiveTable';
import ResponsiveTileView2 from '../ResponsiveTileView/ResponsiveTileView2';
import MobileHeaderLinks from '../MobileHeaderLinks';
import SideBarNavigationMobile from '../SideBarNavigationMobile';

import { translate, getDateInLocalFormat, filterBy, useIsBigScreen } from '../../helper/functions';
import { navigateWithRules, calculateRoutePath } from '../../helper/navigation';
import * as moduleActions from '../../actions/modules';
import * as errorActions from '../../actions/error';

import './Submissions.scss';

import bell from '../../assets/bell_grey_default.svg';

const Submissions = (props) => {
  const {
    modules,
    token,
    stages,
    history,
    setModuleData,
    submissions,
    setSubmissions,
    userData,
    subPageTitle,
    throwError,
    navigation,
    backButton,
  } = props;
  const defaultSearchAndFilter = {
    search: '',
    module: '',
    state: '',
    onlyBlocked: false,
  };
  const [searches, setSearches] = useState(defaultSearchAndFilter);
  const [submissionToEdit, setSubmissionToEdit] = useState('');
  const [newSubmissionName, setNewSubmissionName] = useState('');
  const [tableRows, openTableRows] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const isBigScreen = useIsBigScreen();

  useEffect(() => {
    setFilteredSubmissions(
      submissions
        .map((s) => ({
          ...s,
          state: s.completed ? 'completed' : 'open',
          module: modules[s.moduleId] ? translate(modules[s.moduleId].label) : undefined,
        }))
        .filter(
          (s) =>
            s.module &&
            filterBy(
              s,
              [
                { type: 'text', itemProperties: ['name', 'module'], searchProperty: 'search' },
                { type: 'selector', itemProperties: ['module'], searchProperty: 'module' },
                { type: 'selector', itemProperties: ['state'], searchProperty: 'state' },
                { type: 'boolean', itemProperties: ['isBlocked'], searchProperty: 'onlyBlocked' },
              ],
              searches
            )
        )
    );
  }, [searches, submissions]);

  const usersMainRoute = userData && userData.mainRoute;
  const tableHeaderColumns = [
    { name: I18n.t('submission-table/table-header-1'), class: 'col-4', sortName: 'submissionName' },
    { name: I18n.t('submission-table/table-header-2'), class: 'col-2', sortName: 'moduleId' },
    { name: I18n.t('submission-table/table-header-3'), class: '', sortName: 'created' },
    { name: I18n.t('submission-table/table-header-4'), class: '', sortName: 'updated' },
    { name: I18n.t('submission-table/table-header-5'), class: 'status', sortName: 'state' },
    { name: I18n.t('submission-table/table-header-6'), class: 'notification' },
  ];

  const setOpenTableRows = (submission, e) => {
    e.stopPropagation();
    const { _id, isBlocked, moduleId } = submission;
    let openRows = [...tableRows];
    if (isBlocked && !stages[isBlocked]) {
      axios
        .get('/drf/modules/' + moduleId, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          setModuleData(response.data);
        })
        .catch((e) => throwError(e));
    }
    if (openRows.includes(_id)) {
      openRows.splice(openRows.indexOf(_id), 1);
    } else {
      openRows.push(_id);
    }
    openTableRows(openRows);
  };
  const renderAttentionContainer = (isBlocked) => {
    let stageName = stages[isBlocked] && stages[isBlocked].description ? translate(stages[isBlocked].description) : '';
    let attention =
      stages[isBlocked] && stages[isBlocked].blockContent ? translate(stages[isBlocked].blockContent) : isBlocked;
    return (
      <div className="attention-container">
        <div className="attention-header">
          <span>{stageName} </span>
        </div>
        <div className="attention-content">
          <span dangerouslySetInnerHTML={{ __html: attention }} />
        </div>
      </div>
    );
  };
  const toSubmission = (submission) => {
    if (submission._id === submissionToEdit) return null;
    axios
      .get('/drf/modules/' + submission.moduleId, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        setModuleData(response.data);
        if (submission.completed) {
          history.push(
            calculateRoutePath(
              usersMainRoute,
              '/client-management/' +
                submission._id +
                '/' +
                response.data.stages.find((stage) => stage.type === 'summary')._id
            )
          );
        } else if (submission.isBlocked && response.data.stages.find((s) => s._id === submission.isBlocked)) {
          history.push(
            calculateRoutePath(usersMainRoute, '/client-management/' + submission._id + '/' + submission.isBlocked)
          );
        } else if (submission.stageId) {
          history.push(
            calculateRoutePath(usersMainRoute, '/client-management/' + submission._id + '/' + submission.stageId)
          );
        } else {
          history.push(
            calculateRoutePath(
              usersMainRoute,
              '/client-management/' + submission._id + '/' + response.data.stageMeta[0].stageId
            )
          );
        }
      })
      .catch((e) => {
        throwError(e);
      });
  };
  const toAdminSubmissionView = (submission) => {
    history.push(calculateRoutePath(usersMainRoute, `/client-management/admin/${submission._id}/data`));
  };
  const navigateToSubmission = (submission) => {
    navigateWithRules(
      userData,
      ['complete-submission'],
      () => toAdminSubmissionView(submission),
      () => toSubmission(submission)
    );
  };
  const editSubmissionName = (e, s) => {
    if (e.key === 'Enter' || e.type === 'mousedown') {
      axios
        .put(
          '/drf/submissions/' + submissionToEdit + '/meta',
          {
            name: newSubmissionName.length > 0 ? newSubmissionName : s.name,
          },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((response) => {
          const submissionIndexToUpdate = submissions.findIndex((s) => s._id === submissionToEdit);
          setSubmissions([
            ...submissions.slice(0, submissionIndexToUpdate),
            {
              ...submissions[submissionIndexToUpdate],
              name: newSubmissionName.length > 0 ? newSubmissionName : s.name,
            },
            ...submissions.slice(submissionIndexToUpdate + 1),
          ]);
          setSubmissionToEdit('');
        })
        .catch((e) => throwError(e));
    }
  };

  const filteredModules = Object.values(modules).filter(
    (item) => !item.autoCreateEntity || submissions.map((s) => s.moduleId).includes(item._id)
  );
  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredSubmissions.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () => {
        setSearches({
          ...searches,
          search: event.target.value,
        });
      },
    },
    switchers: [
      {
        text: 'entity-details-header/only-blocked-switcher',
        checked: searches.onlyBlocked,
        onSwitcherChange: () => {
          setSearches({
            ...searches,
            onlyBlocked: event.target.checked,
          });
        },
      },
    ],
    selects: [
      ...(filteredModules.length > 1
        ? [
            {
              name: 'module',
              className: 'module-selector',
              selected: searches.module,
              onSelectChange: (selectValue) =>
                setSearches({
                  ...searches,
                  module: selectValue,
                }),
              options: filteredModules.map((m) => ({
                value: translate(m.label),
                label: translate(m.label),
              })),
            },
          ]
        : []),
      {
        name: 'state',
        className: 'state-selector',
        selected: searches.state,
        onSelectChange: (selectValue) =>
          setSearches({
            ...searches,
            state: selectValue,
          }),
        options: [
          {
            value: 'completed',
            label: I18n.t('general/completed'),
          },
          {
            value: 'open',
            label: I18n.t('general/open'),
          },
        ],
      },
    ],
    clearSearchAndFilter: () => setSearches(defaultSearchAndFilter),
  };

  return (
    <>
      <ResponsiveSubHeader subPageTitle={subPageTitle} backButton={backButton} filterbarProps={filterbarProps} />
      {!isBigScreen && <SideBarNavigationMobile navigation={navigation} />}
      {isBigScreen ? renderTableView() : renderTileView()}
      {isBigScreen ? null : <MobileHeaderLinks />}
    </>
  );

  function renderTableView() {
    return (
      <ResponsiveTable
        headerColumns={tableHeaderColumns}
        data={filteredSubmissions}
        className="submissions-table-container"
        defaultSort="created"
        renderRow={(s, options) => (
          <div className="table-row" key={options.index} onClick={() => navigateToSubmission(s)}>
            <div className={'column col-4 sticky ' + s.state}>
              <div>
                <EditableName
                  isEditing={submissionToEdit === s._id}
                  name={s.name}
                  value={newSubmissionName}
                  editor={setNewSubmissionName}
                  setter={editSubmissionName}
                  objectToEdit={s}
                  setActivity={setSubmissionToEdit}
                />
              </div>
            </div>
            <div className="row column col-2 bigger-padding">
              <TextWithTooltip textWithOverflow={modules && translate(modules[s.moduleId].label)} />
            </div>
            <div className="column date">{getDateInLocalFormat(s.created)}</div>
            <div className="column date">{s.updated ? getDateInLocalFormat(s.updated) : '-'}</div>
            <div className="column status">
              <StatusIndicator state={s.state} />
            </div>
            <div
              className={'column notification bell ' + (s.isBlocked ? 'blocked' : '')}
              onClick={(e) => s.isBlocked && setOpenTableRows(s, e)}
            >
              {!s.isBlocked && (
                <Svg
                  src={bell}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                ></Svg>
              )}
            </div>
            <div className="break"></div>
            {s.isBlocked && tableRows.includes(s._id) && (
              <div className="column">
                <div className="inner-container" style={{ width: options.containerWidth }}>
                  {renderAttentionContainer(s.isBlocked)}
                </div>
              </div>
            )}
          </div>
        )}
      />
    );
  }

  function renderTileView() {
    return (
      <ResponsiveTileView2
        tileContent={filteredSubmissions}
        tableHeaderColumns={tableHeaderColumns}
        renderRow={renderRow}
        onTileClick={(s) => navigateToSubmission(s)}
      />
    );

    function renderRow(column, content, index) {
      return (
        <div key={index} className="tile-content-row">
          <span className="content-key">{column.name}</span>
          <span className="content-value">{content[column.sortName]}</span>
        </div>
      );
    }
  }
};

Submissions.propTypes = {
  modules: PropTypes.object,
  token: PropTypes.string,
  stages: PropTypes.object,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    modules: state.modules.modules,
    stages: state.modules.stages,
    userData: state.auth.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setModuleData: moduleActions.setModuleData,
      throwError: errorActions.throwError,
    },
    dispatch
  );
}

export default Authorization(connect(mapStateToProps, mapDispatchToProps)(Submissions), ['list-submissions']);
