import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import ClientDocumentTable from '../ClientDocumentTable';
import Authorization from '../Authorization';
import ResponsiveSubHeader from '../SubHeader/ResponsiveSubHeader';
import SideBarNavigationMobile from '../SideBarNavigationMobile';
import MobileHeaderLinks from '../MobileHeaderLinks';

import { translate, downloadDocumentWithBlob, useIsBigScreen } from '../../helper/functions';
import { getGroupedDocuments, addLabelsToGroupedDocuments } from '../DocumentLibrary/document-library-logic';

import '../DocumentLibrary/DocumentLibrary.scss';
import './DataVault.scss';

const DataVault = (props) => {
  const { token, documents, subPageTitle, modules, submissions, entity, groups, navigation, backButton } = props;

  const defaultSearchAndFilter = {
    search: '',
    module: '',
  };
  const [searchAndFilter, setSearchAndFilter] = useState(defaultSearchAndFilter);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [groupedFilteredDocuments, setGroupedFilteredDocuments] = useState({});
  const isBigScreen = useIsBigScreen();

  useEffect(() => {
    setFilteredDocuments(
      documents
        .map((d) => ({ ...d, moduleId: submissions.find((s) => s._id === d.submissionId).moduleId }))
        .filter((doc) => onlyNotHidden(doc) && onlyMatchingLabels(doc) && onlyMatchingModule(doc))
    );
  }, [documents, searchAndFilter]);

  useEffect(() => {
    if (!entity.entityData || !filteredDocuments || !groups || Object.keys(groups).length === 0) {
      return;
    }

    const groupedDocuments = getGroupedDocuments(filteredDocuments);
    setGroupedFilteredDocuments(addLabelsToGroupedDocuments(groupedDocuments, entity.entityData, groups));
  }, [filteredDocuments, entity.entityData, groups]);

  const onlyNotHidden = (doc) => !doc.hidden;
  const htmlElements = /<(.*?)>/g;
  const onlyMatchingLabels = (doc) =>
    translate(doc.label)
      .replace(htmlElements, '')
      .toLowerCase()
      .includes(searchAndFilter.search.toLowerCase());
  const onlyMatchingModule = (doc) => ['', 'all', doc.moduleId].includes(searchAndFilter.module);
  const downloadDocument = (doc, withFilePath = false) => {
    downloadDocumentWithBlob(doc, doc.submissionId, token, withFilePath);
  };

  const existingModules = [
    ...new Set(documents.map((d) => submissions.find((s) => s._id === d.submissionId).moduleId)),
  ];

  const filterbarProps = {
    searchbar: {
      shown: true,
      resultsLength: filteredDocuments.length,
      type: 'text',
      placeholder: 'entity-details/search-for',
      onSearchChange: () =>
        setSearchAndFilter({
          ...searchAndFilter,
          search: event.target.value,
        }),
    },
    selects: [
      ...(existingModules.length > 1
        ? [
            {
              name: 'module',
              className: 'module-selector',
              selected: searchAndFilter.module,
              onSelectChange: (selectValue) =>
                setSearchAndFilter({
                  ...searchAndFilter,
                  module: selectValue,
                }),
              options: existingModules.map((m) => ({ value: m, label: translate(modules[m].label) })),
            },
          ]
        : []),
    ],
    clearSearchAndFilter: () => setSearchAndFilter(defaultSearchAndFilter),
  };

  return (
    <Fragment>
      <ResponsiveSubHeader subPageTitle={subPageTitle} backButton={backButton} filterbarProps={filterbarProps} />
      {!isBigScreen && <SideBarNavigationMobile navigation={navigation} />}
      <div className="document-library-content-container hide-scrollbar">
        <ClientDocumentTable
          documents={groupedFilteredDocuments}
          downloadDocument={downloadDocument}
          showDownloadAndUpload={false}
        />
      </div>
      {isBigScreen ? null : <MobileHeaderLinks />}
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    modules: state.modules.modules,
    groups: state.modules.groups,
  };
}

export default Authorization(connect(mapStateToProps, null)(DataVault), ['list-submission-documents']);
