import { createSlice } from '@reduxjs/toolkit';

import { fieldValidatorForSubmission, mapFieldValues } from '../helper/functions';

export const submissionSlice = createSlice({
  name: 'submissions',
  initialState: {},
  reducers: {
    setSubmissionData: (state, action) => {
      action.payload.data = mapFieldValues(action.payload.data, action.payload.fields);
      return {
        ...state,
        [action.payload.data._id]: {
          ...state[action.payload.data._id],
          ...action.payload.data,
          loading: false,
        },
      };
    },
    setSubmissionLoading: (state, action) => {
      return {
        ...state,
        [action.payload.submissionId]: {
          ...state[action.payload.submissionId],
          loading: action.payload.loading,
        },
      };
    },
    changeValue: (state, action) => {
      const submission = { ...state[action.payload.submissionId] };
      const groupIndex = submission.data.findIndex(
        (d) => d.groupId === action.payload.groupId && d.position === action.payload.groupPosition
      );
      let newData = null;
      const newFieldValueObject = {
        fieldId: action.payload.fieldId,
        value: action.payload.value,
        error: action.payload.error,
      };
      if (action.payload.resultFieldId) {
        newFieldValueObject.resultFieldId = action.payload.resultFieldId;
      }
      if (action.payload.referenceResultId) {
        newFieldValueObject.readonly = true;
      }
      if (groupIndex === -1) {
        // first value set in group
        newData = [
          ...submission.data,
          {
            groupId: action.payload.groupId,
            position: action.payload.groupPosition,
            values: [newFieldValueObject],
          },
        ];
        if (action.payload.referenceResultId) {
          newData[newData.length - 1].referenceResultId = action.payload.referenceResultId;
        }
      } else {
        // change existing group results value
        newData = [
          ...submission.data.slice(0, groupIndex),
          {
            ...submission.data[groupIndex],
            referenceResultId: action.payload.referenceResultId
              ? action.payload.referenceResultId
              : submission.data[groupIndex].referenceResultId,
            values: submission.data[groupIndex].values.find((v) => v.fieldId == action.payload.fieldId)
              ? // change existing fieldvalue
                submission.data[groupIndex].values.map((v) => {
                  if (v.fieldId !== action.payload.fieldId) return v;
                  return {
                    ...v,
                    ...newFieldValueObject,
                  };
                })
              : // add value for new field
                [...submission.data[groupIndex].values, newFieldValueObject],
          },
          ...submission.data.slice(groupIndex + 1),
        ];
      }
      return {
        ...state,
        [action.payload.submissionId]: {
          ...submission,
          data: newData,
        },
      };
    },
    storeOriginalValues: (state, action) => {
      return {
        ...state,
        [action.payload.submissionId]: {
          ...state[action.payload.submissionId],
          originalData: state[action.payload.submissionId].data,
        },
      };
    },
    restoreOriginalValues: (state, action) => {
      return {
        ...state,
        [action.payload.submissionId]: {
          ...state[action.payload.submissionId],
          data: state[action.payload.submissionId].originalData,
        },
      };
    },
    addNewGroupResult: (state, action) => {
      if (!state[action.payload.submissionId].loading) {
        state[action.payload.submissionId].data.push({
          groupId: action.payload.groupId,
          hidden: false,
          position: action.payload.position,
          values: [],
        });
      }
    },
    deleteGroupResult: (state, action) => {
      if (action.payload.shouldDeleteWholeGroupResult) {
        return {
          ...state,
          [action.payload.submissionId]: {
            ...state[action.payload.submissionId],
            data: state[action.payload.submissionId].data
              .filter((resultData) => {
                return resultData.groupId !== action.payload.groupId || resultData.position !== action.payload.position;
              })
              .map((resultData) => {
                if (resultData.groupId === action.payload.groupId && resultData.position > action.payload.position) {
                  return {
                    ...resultData,
                    position: resultData.position - 1,
                  };
                }
                return resultData;
              }),
          },
        };
      } else {
        const data = state[action.payload.submissionId].data.map((resultData) => {
          if (resultData.groupId === action.payload.groupId) {
            return {
              ...resultData,
              referenceResultId: undefined,
              resultId: undefined,
              values: resultData.values.map((d) => {
                const field = action.payload.fields[d.fieldId];
                const error = fieldValidatorForSubmission(
                  null,
                  field.validationRules,
                  field.type,
                  action.payload.submission,
                  resultData.groupId,
                  undefined,
                  action.payload.dependingConditionsFailed
                );

                return { ...d, value: null, error, readonly: false, resultFieldId: undefined };
              }),
            };
          }

          return resultData;
        });

        return {
          ...state,
          [action.payload.submissionId]: {
            ...state[action.payload.submissionId],
            data,
          },
        };
      }
    },
    setStageResults: (state, action) => {
      let groupResults;

      if (!action.payload.status) {
        return state;
      }

      if (action.payload.status.state === 'success') {
        groupResults = state[action.payload.submissionId].data.map((d) => {
          const newGroupResult = action.payload.groupResults.find(
            (gr) => gr.groupId === d.groupId && gr.position === d.position
          );
          if (newGroupResult) {
            return newGroupResult;
          } else {
            return d;
          }
        });
      } else {
        groupResults = state[action.payload.submissionId].data.map((d) => {
          if (Array.isArray(action.payload.status.errors)) {
            action.payload.status.errors.map((e) => {
              const errorFound = d.values.find((v) => v.fieldId === e.property);

              if (errorFound) {
                errorFound.error = e.error;
              }
            });
          }

          return d;
        });
      }

      return {
        ...state,
        [action.payload.submissionId]: {
          ...state[action.payload.submissionId],
          loading: action.payload.status.state !== 'success' ? false : state[action.payload.submissionId].loading,
          data: groupResults,
          stageHistory: action.payload.stageId
            ? state[action.payload.submissionId].stageHistory.includes(action.payload.stageId)
              ? state[action.payload.submissionId].stageHistory
              : [...state[action.payload.submissionId].stageHistory, action.payload.stageId]
            : state[action.payload.submissionId].stageHistory,
        },
      };
    },
    setSubmissionSettings: (state, action) => {
      return {
        ...state,
        [action.payload.submissionId]: {
          ...state[action.payload.submissionId],
          data: state[action.payload.submissionId].data.map((groupResult) => {
            if (groupResult.resultId !== action.payload.resultId) {
              return groupResult;
            }
            return {
              ...groupResult,
              [action.payload.key]: action.payload.groupSettings[action.payload.key],
              values: groupResult.values.map((v) => {
                const newFieldSetting = action.payload.groupSettings.fields.find(
                  (gsField) => gsField.resultFieldId === v.resultFieldId
                );
                if (newFieldSetting) {
                  return {
                    ...v,
                    [action.payload.key]: newFieldSetting[action.payload.key],
                  };
                }
                return v;
              }),
            };
          }),
        },
      };
    },
    setSubmissionOverride: (state, action) => {
      return {
        ...state,
        [action.payload.submissionId]: {
          ...state[action.payload.submissionId],
          isBlocked: action.payload.isBlocked,
        },
      };
    },
    setDocuments: (state, action) => {
      return {
        ...state,
        [action.payload.submissionId]: {
          ...state[action.payload.submissionId],
          documents: action.payload.documents,
        },
      };
    },
    setErroredDocuments: (state, action) => {
      return {
        ...state,
        [action.payload.submissionId]: {
          ...state[action.payload.submissionId],
          documents: action.payload.documents.map((d) => {
            const isErrored = action.payload.erroredDocuments.some((ed) => ed._id === d._id);
            if (isErrored) {
              return { ...d, dataFlow: 'error' };
            }

            return d;
          }),
        },
      };
    },
    validateFields: (state, action) => {
      const submissionId = action.payload.submissionId;
      const submission = state[submissionId];
      const errors = action.payload.errors;
      const fields = action.payload.fields;

      const data = submission.data.map((groupResult) => {
        return {
          ...groupResult,
          values: groupResult.values.map((v) => {
            const foundError = errors.find((e) => e.fieldId === v.fieldId);
            if (foundError) {
              v.error = foundError.message;
            }

            return v;
          }),
        };
      });

      for (let i = 0; i < errors.length; i++) {
        for (let j = 0; j < fields[errors[i].fieldId].dependsOn.length; j++) {
          for (let k = 0; k < data.length; k++) {
            for (let l = 0; l < data[k].values.length; l++) {
              if (data[k].values[l].fieldId === fields[errors[i].fieldId].dependsOn[j].fieldId) {
                data[k].values[l].error = errors[i].message;
              }
            }
          }
        }
      }

      return {
        ...state,
        [submissionId]: {
          ...state[submissionId],
          data,
        },
      };
    },
  },
  extraReducers: {
    LOGOUT: () => {
      return {};
    },
  },
});

export const {
  setSubmissionData,
  setSubmissionLoading,
  changeValue,
  storeOriginalValues,
  restoreOriginalValues,
  addNewGroupResult,
  deleteGroupResult,
  setStageResults,
  setSubmissionSettings,
  setSubmissionOverride,
  setDocuments,
  setErroredDocuments,
  validateFields,
} = submissionSlice.actions;

export default submissionSlice.reducer;
