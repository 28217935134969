import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import GroupResult from '../GroupResult';

import * as submissionThunks from '../../thunks/submission';

import './DataCheckStage.scss';

class DataCheckStage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getSubmission(this.props.match.params.submissionId, this.props.token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.submissionId !== prevProps.match.params.submissionId) {
      this.props.getSubmission(this.props.match.params.submissionId, this.props.token);
    }
  }

  render() {
    if (!this.props.filteredStageMeta || !this.props.submission) {
      return null;
    }
    const { filteredStageMeta, submission, stages } = this.props;
    return (
      <div>
        <div className="data-check-container">
          <div className={'data-container'}>
            {filteredStageMeta.map(({ stageId }) => {
              const stage = stages[stageId];
              return stage.groupMeta.map(({ groupId }) => {
                const groupResults = submission.data.filter((result) => result.groupId === groupId);
                return groupResults.map((result, j) => {
                  !this.firstItem && (this.firstItem = result.groupId);

                  return (
                    <GroupResult
                      isSubmissionCompleted={submission.completed}
                      result={result}
                      stageId={stageId}
                      firstItem={this.firstItem === result.groupId}
                      key={j}
                    />
                  );
                });
              });
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const submissionId = ownProps.match.params.submissionId;
  const submission = state.submissions[submissionId];

  return {
    token: state.auth.token,
    groups: state.modules.groups,
    stages: state.modules.stages,
    userData: state.auth.userData,
    submission,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSubmission: submissionThunks.getSubmission,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataCheckStage));
