import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';

import ClientManagement from '../ClientManagement';
import UserManagement from '../UserManagement';
import DataManagement from '../DataManagement/DataManagement';
import ErrorBoundary from '../ErrorBoundary';
import Header from '../Header';
import ScrollToTop from '../ScrollToTop';
import Svg from '../Svg';
import WrappedLink from '../WrappedLink';

import * as authActions from '../../actions/auth';
import * as modulesActions from '../../actions/modules';
import * as errorActions from '../../actions/error';
import { calculateRoutePath } from '../../helper/navigation';
import { getHeaderLinks } from '../../helper/functions';

import './Dashboard.scss';

import profile from '../../assets/user_icon_white.svg';

class Dashboard extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      menuShown: false,
    };
    this.headerLinks = getHeaderLinks();
    this.dropdownRef = createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    axios
      .get('/drf/modules', {
        headers: {
          Authorization: 'Bearer ' + this.props.token,
        },
      })
      .then((response) => {
        this.props.setModules(response.data);
      })
      .catch((e) => {
        this.props.throwError(e);
      });
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ menuShown: false }, () => {
        if (!this.state.menuShown) {
          document.removeEventListener('mousedown', this.handleClickOutside);
        }
      });
    }
  };

  toggleMenu = () => {
    !this.state.menuShown
      ? document.addEventListener('mousedown', this.handleClickOutside)
      : document.removeEventListener('mousedown', this.handleClickOutside);
    this.setState({ menuShown: !this.state.menuShown });
  };

  handleChange = (selected) => {
    this.setSection(selected.sectionId);
  };

  logout = async () => {
    try {
      await axios.post(
        '/auth/logout',
        {},
        {
          headers: {
            Authorization: 'Bearer ' + this.props.token,
          },
        }
      );
    } catch (e) {
      console.error(e.response);
    }
    this.props.logout();
  };

  render() {
    if (!this.props.authenticated) {
      return null;
    }
    const { userData } = this.props;
    const usersMainRoute = userData && userData.mainRoute;

    return (
      <div className="dashboard-container">
        <Header links={this.headerLinks} logout={this.logout}>
          <div className="right-container">
            <div className="profile" ref={this.dropdownRef} onClick={() => this.toggleMenu()}>
              <Svg src={profile} otherColor={clientConfig.colors.headerText} hasHover={true} />
              {this.state.menuShown && (
                <div className="dropdown-menu">
                  <ul>
                    <WrappedLink to={'/profile'} className="dropdown-menu-item">
                      <li>
                        <Translate value="menu/profile" />
                      </li>
                    </WrappedLink>
                    <div className="dropdown-menu-item">
                      <li
                        onClick={async () => {
                          await this.logout();
                          this.props.history.push('/login');
                        }}
                      >
                        <Translate value="menu/logout" />
                      </li>
                    </div>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Header>
        <div className="content-container">
          <ErrorBoundary {...this.props}>
            <ScrollToTop>
              <Switch>
                <Route path={calculateRoutePath(usersMainRoute, `/user-management`)} component={UserManagement} />
                <Route path={calculateRoutePath(usersMainRoute, `/client-management`)} component={ClientManagement} />
                <Route path={calculateRoutePath(usersMainRoute, `/data-management`)} component={DataManagement} />
                <Redirect from={``} to={calculateRoutePath(usersMainRoute, `/client-management`)} />
              </Switch>
            </ScrollToTop>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  authenticated: PropTypes.bool,
  logout: PropTypes.func,
  token: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    token: state.auth.token,
    userData: state.auth.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: authActions.logout,
      setModules: modulesActions.setModules,
      throwError: errorActions.throwServerError,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
